import {requestBytes, torchFetch} from "../util/APIUtils";
import {API_BASE_URL} from "../constants";
import store from "store/dist/store.everything";
import {
    ChargeLineItem,
    Feedback,
    LaneActivityLoad,
    Load,
    LoadFeedbackType,
    LoadRep,
    NewFeedback,
    SkinnyLoad
} from "../interfaces/Load";
import {SuggestedCarrier} from "../interfaces/SuggestedCarrier";
import {Address} from "../interfaces/Address";
import {addHours} from "date-fns";
import {EdiSource} from "../interfaces/Edi";
import {ShipwellEvent} from "../interfaces/Event";
import {DatRateForLoad, TNTRequest} from "../interfaces/RateData";
import {RFPRequest} from "../interfaces/RFPRequest";
import {TourData} from "../interfaces/Tour";
import {LoadNote} from "../interfaces/LoadNote";
import {Capacity} from "../interfaces/Capacity";
import {ShipmentDocument} from "../interfaces/ShipmentDocument";

const DEFAULT_EXPIRATION = 1000 * 10; // 10 seconds for dev

export function getOpenLoad(loadId): Promise<Load> {
    return new Promise((resolve, reject) => {
        const url = new URL(`${API_BASE_URL}/procurement/loads/${loadId}`);
        torchFetch({url: url}).then(json => {
            const processed = processOpenLoads(json.content);
            //setCache("openLoads", processed);
            //setIndividualLoadsInCache(processed);
            resolve(processed[0]);
        });
    });
}

// returns 0->n loads with condensed data about the loads for list display purposes
export function getSkinnyOpenLoads(): Promise<SkinnyLoad[]> {
    return new Promise((resolve, reject) => {
        const url = new URL(`${API_BASE_URL}/procurement/loads/condensed?size=250`);

        torchFetch({url: url}).then(json => {
            resolve(json.content);
        })
    })
}

export function getSkinnyDeliveredLoads(): Promise<SkinnyLoad[]> {
    return new Promise((resolve, reject) => {
        const url = new URL(`${API_BASE_URL}/procurement/loads/delivered/invoice`);
        torchFetch({url: url}).then(json => {
            resolve(json.content);
        })
    })
}

export function getSkinnyDeliveredLoadsDaysBack(daysBack:string): Promise<SkinnyLoad[]> {
    return new Promise((resolve, reject) => {
        const url = new URL(`${API_BASE_URL}/procurement/loads/delivered/invoice?daysBack=${daysBack}`);
        torchFetch({url: url}).then(json => {
            resolve(json.content);
        })
    })
}


// returns 0->n loads with the loads and other analytics data about the load such as other offers, number of
// activities, etc.
export function getOpenLoads(): Promise<Load[]> {
    return new Promise((resolve, reject) => {
        const cached = getFromCache("openLoads");
        if (cached) {
            resolve(cached);
        } else {
            //const url = new URL(API_BASE_URL + "/load?filter=quoting&size=250");
            const url = new URL(`${API_BASE_URL}/procurement/loads?size=250`);
            torchFetch({url: url}).then(json => {
                const processed = processOpenLoads(json.content);
                //setCache("openLoads", processed);
                //setIndividualLoadsInCache(processed);
                resolve(processed);
            })
        }
    })
}

export function getAllLoadsWithoutFacilities(): Promise<Load[]> {
    return new Promise((resolve, reject) => {
        const url = new URL(API_BASE_URL + "/load?filter=needs_facility&size=250");
        torchFetch({url: url}).then(json => {
            resolve(json.content);
        })
    })
}


const processOpenLoads = (content) => {
    return content.map(c => {
        c.load.offers = c.offers;
        c.load.activityCount = c.activityCount;
        c.load.feedback = c.feedback;
        c.load.bestOffers = c.bestOffers;
        c.load.sameLaneOffers = c.sameLaneOffers;
        return c.load;
    });
}

export function getLoadReps(loadId: number): Promise<LoadRep[]> {
    return torchFetch({url: `${API_BASE_URL}/load/${loadId}/reps`});
}

export function getLoadLineItems(loadId: number): Promise<ChargeLineItem[]> {
    return torchFetch({url: `${API_BASE_URL}/load/${loadId}/charge-line-items`});
}

export function getLoad(loadId): Promise<Load> {
    return new Promise((resolve, reject) => {
        const url = new URL(API_BASE_URL + `/load/${loadId}`);
        torchFetch({url: url}).then(json => {
            // dates come in as strings (iso8601) but our type is a Date
            // This fixes that so higher level functions don't have to
            // worry about it
            (json as Load).stops.forEach(stop => {
                stop.arrival = new Date(stop.arrival);
                stop.departure = new Date(stop.departure);
            });
            resolve(json);
        })
    });
}

/**
 * Returns a list of the open loads between the origin and destination.
 *
 * @param origin
 * @param destination
 */
export function openLoadsByLane(origin: Address, destination: Address) {
    const cacheKey = "openLoadsByLane." + origin.city + "," + origin.state + "_" +
        destination.city + "," + destination.state;

    return new Promise((resolve, reject) => {
        const cached = getFromCache(cacheKey);
        if (cached) {
            resolve(cached);
        } else {
            const loadsUrl = new URL(`${API_BASE_URL}/load/open_by_lane`),
                params = {origin: origin.zipCode, destination: destination.zipCode}
            Object.keys(params).forEach(key => loadsUrl.searchParams.append(key, params[key]))
            torchFetch({url: loadsUrl}).then(json => {
                setCache(cacheKey, json.content);
                setIndividualLoadsInCache(json.content);
                resolve(json.content);
            });
        }
    })
}

export type LocationsWithLoads = {
    location: string;
    loads: Load[];
}

export function findOpenLoadsForLanes(lanes: string): Promise<LocationsWithLoads[]> {
    return torchFetch({
        url: API_BASE_URL + `/procurement/loads/near-locations`,
        method: 'POST',
        body: JSON.stringify({
            locations: lanes.split(/\r?\n/)
        })
    });
}


/**
 * Returns a list of the most recent loads that have been between the origin and destination.
 *
 * @param origin
 * @param destination
 */
export function loadsByLane(origin: Address, destination: Address): Promise<Load[]> {
    const cacheKey = "loadsByLane." + origin.city + "," + origin.state + "_" +
        destination.city + "," + destination.state;

    return new Promise((resolve, reject) => {
        const cached = getFromCache(cacheKey);
        if (cached) {
            resolve(cached);
        } else {
            const loadsUrl = new URL(`${API_BASE_URL}/load/by_lane`),
                params = {origin: origin.zipCode, destination: destination.zipCode}
            Object.keys(params).forEach(key => loadsUrl.searchParams.append(key, params[key]))
            torchFetch({url: loadsUrl}).then(json => {
                setCache(cacheKey, json.content);
                setIndividualLoadsInCache(json.content);
                resolve(json.content);
            });
        }
    })
}

/**
 * Retrieve load activity within a given lane, as defined by the origin/destination provided.
 *
 * @param origin
 * @param destination
 */
export function getLaneActivityLoads(origin: Address, destination: Address): Promise<LaneActivityLoad[]> {
    const path = `${origin.zipCode}_${destination.zipCode}`;
    const url = new URL(`${API_BASE_URL}/lane-activity/${path}/loads`);
    return new Promise((resolve) => {
        torchFetch({url: url}).then(json => {
            resolve(json);
        });
    });
}


export function sendFeedback(loadId: number | string, carrierId: number | string, feedback: NewFeedback) {
    return torchFetch({
        url: API_BASE_URL + `/load/${loadId}/carrier/${carrierId}/feedback`,
        method: 'POST',
        body: JSON.stringify(feedback)
    });
}

export function noAnswerFeedback(loadId: number | string, carrierId: number | string) {
    const feedback = {
        type: "NO_ANSWER" as LoadFeedbackType, declineReasons: null, notes: null,
        expiration: addHours(new Date(), 12)
    };
    return sendFeedback(loadId, carrierId, feedback);
}

export function feedbackForLoad(loadId: number | string): Promise<Feedback[]> {
    return torchFetch({
        url: API_BASE_URL + `/load/${loadId}/feedback`
    });
}

export function queryLoads(query: string): Promise<Load[]> {
    return torchFetch({url: `${API_BASE_URL}/loads?q=${query}`});
}

export function getEdiSourcesForLoad(loadId: number): Promise<EdiSource[]> {
    return torchFetch({url: `${API_BASE_URL}/loads/${loadId}/edi`});
}

export function getLoadNotesForLoad(loadId: number): Promise<LoadNote[]> {
    return torchFetch({url: `${API_BASE_URL}/load/${loadId}/notes`});
}

export function getShipwellEventsForLoad(loadId: number): Promise<ShipwellEvent[]> {
    return torchFetch({url: `${API_BASE_URL}/loads/${loadId}/events`});
}

export function getSkinnyInProgressLoads(): Promise<SkinnyLoad[]> {
    return new Promise((resolve, reject) => {
        const url = new URL(`${API_BASE_URL}/procurement/loads/in_progress`);
        torchFetch({url: url}).then(json => {
            resolve(json.content);
        })
    })
}

/**
 * Pulls a list of recommended carriers for the given load.  This should be a curated
 * list of carriers that should be called and should not contain carriers that have
 * declined the load already.
 *
 * @param loadId
 */
export function getCarriersForLoad(loadId: string): Promise<SuggestedCarrier[]> {
    const carriersUrl = new URL(`${API_BASE_URL}/load/${loadId}/recommended-carriers`);
    return torchFetch({url: carriersUrl});
}

export function datCarriersForLoad(load) {
    const carriersUrl = new URL(`${API_BASE_URL}/load/${load.id}/dat-carriers`);
    return torchFetch({url: carriersUrl});
}

export function datCarriersForLoadId(loadId) {
    const carriersUrl = new URL(`${API_BASE_URL}/load/${loadId}/dat-carriers`);
    return torchFetch({url: carriersUrl});
}

export function fetchSuggestedCarrierForLoad(loadId, carrierId) {
    const url = new URL(`${API_BASE_URL}/load/${loadId}/recommended-carrier/${carrierId}`);
    return torchFetch({url: url});
}

export function fetchWeatherForLoad(state) {
    const url = new URL(`${API_BASE_URL}/weather/alert/${state}`);
    return torchFetch({url: url});
}

export function requestShipwellResync(loadRef): Promise<Load> {
    const url = new URL(`${API_BASE_URL}/shipwell-debug/refreshShipment?ref_id=${loadRef}`)
    return torchFetch({url: url});
}

export function getDatRateForLoad(loadId: number): Promise<DatRateForLoad | null> {
    const url = new URL(`${API_BASE_URL}/load/${loadId}/dat-rate`);
    return torchFetch({url: url});
}

export function getTNTRequestForLoad(loadId: number): Promise<TNTRequest | null> {
    const url = new URL(`${API_BASE_URL}/load/${loadId}/tnt`);
    return torchFetch({url: url});
}

const setIndividualLoadsInCache = (loadList) => {
    loadList.forEach(load => {
        setCache("load." + load.id, load);
    })
}

const setCache = (k, v, expiration = new Date().getTime() + DEFAULT_EXPIRATION) => {
    store.set(k, v, expiration);
}

const getFromCache = (k) => {
    return store.get(k);
}

export function getRFPs(): Promise<RFPRequest[]> {
    const url = new URL(`${API_BASE_URL}/rfp`);
    return torchFetch({url: url});
}

export function updateRFP(rfpRequest:RFPRequest) {
    return torchFetch({
        url: API_BASE_URL + `/rfp`,
        method: 'POST',
        body: JSON.stringify(rfpRequest)
    });
}

export function runRFP(rfpId:number) {
    return torchFetch({
        url: API_BASE_URL + `/rfp/${rfpId}/run/`,
        method: 'GET',
    });
}

export function deleteRFP(id:number) {
    return torchFetch({
        url: API_BASE_URL + `/rfp/${id}`,
        method: 'DELETE'
    });
}

export function sendSMSToDriver(loadId: number | undefined,
                                phone: string | undefined,
                                type:string | undefined) : Promise<TNTRequest | null>{

    let phoneEncode = phone;
    if (phone) {
        phoneEncode = encodeURIComponent(phone);
    }
    return torchFetch({
        url: API_BASE_URL + `/load/${loadId}/tnt/sms?phone=${phoneEncode}&type=${type}`,
        method: 'GET'
    });
}

export function getTours(radius:number, continuingRadius:number, days:string): Promise<TourData[]> {
    const url = new URL(`${API_BASE_URL}/load/beta/tours?radius=${radius}&continuationRadius=${continuingRadius}&timeInBetween=${days}`);
    return torchFetch({url: url});
}

export function getLoadCapacity(id:number): Promise<Capacity[]> {
    const url = new URL(`${API_BASE_URL}/load/${id}/capacity/recent`);
    return torchFetch({url: url});
}

export function getShipmentDocuments(id:number): Promise<ShipmentDocument[]> {
    const url = new URL(`${API_BASE_URL}/invoice/pdf/selected/${id}/`);
    return torchFetch({url: url});
}

export function buildSingleDocument(id:number, selections:ShipmentDocument[]): Promise<any> {
    const url = new URL(`${API_BASE_URL}/invoice/pdf/selected/${id}`);
    return requestBytes({
        url: url,
        method: 'post',
        body: JSON.stringify(selections),
        responseType: 'blob'
    })
}

export function buildSelectedCSV(loadIds:Array<String>): Promise<any> {
    const url = new URL(`${API_BASE_URL}/invoice/csv/list`);
    return requestBytes({
        url: url,
        method: 'post',
        body: JSON.stringify(loadIds),
        responseType: 'blob'
    })
}


export function sendInvoiceFromJetpack(loadId: number | undefined) : Promise<string | null>{
    return torchFetch({
        url: API_BASE_URL + `/invoice/${loadId}`,
        method: 'GET'
    });
}


/*
    Need to utilize these - normally wouldn't leave in but will be doing this soon

const removeFromCache = (k) => {
    store.remove(k);
}

// use with care!
const clearEntireCache = () => {
    store.clearAll()();
}
 */


