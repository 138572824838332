import React, {useEffect, useState} from 'react';
import {styled, useTheme} from '@mui/material/styles';
import './App.css';
import Rate from './rates/Rate.js';
import Account from './account/Account';
import Carriers from './carrier/Carriers';
import TourIcon from '@mui/icons-material/Tour';
import {
    AccountBalance,
    AirplaneTicket,
    AllInbox,
    AssignmentTurnedIn,
    Ballot,
    ConnectWithoutContact,
    Details,
    LocalShipping,
    LocationSearchingTwoTone,
    MonetizationOn,
    Payments,
    Queue,
    Search,
    Settings,
    Shop,
    Warehouse
} from '@mui/icons-material';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

import {Route, Switch, useHistory, useLocation} from "react-router-dom";

import {getCurrentUser} from './util/APIUtils';
import {ACCESS_TOKEN, API_BASE_URL} from './constants';
import PrivateRoute from './common/PrivateRoute';
import OAuth2RedirectHandler from './user/OAuth2RedirectHandler';
import Login from './user/Login';

import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
//import {getProductFeedbackToken} from "./service/slack";
import Tender from "./tender/Tender";
import Button from "@mui/material/Button";
import {Alert, Snackbar} from "@mui/material";
import SettingsMain from "./settings/SettingsMain";
import Integrations from "./integrations/Integrations";
import Box from "@mui/material/Box";
import OpenLoads from "./openloads/OpenLoads";
import OpenLoadContainer from "./openloads/OpenLoadContainer";
import Leads from "./leads/Leads";
import {RecoilRoot} from "recoil";
import SockJsClient from 'react-stomp';
import {nextMessage, socketEvent$} from "./service/socket";
import Loads from "./loads/Loads";

import Facilities from "./facility/Facilities";
import FacilityHome from "./facility/FacilityHome";
import FacilityCreate from "./facility/FacilityCreate";
import ReleaseNotesLink from "./common/ReleaseNotesLink";
import StartSearch from "./search/StartSearch";
import Workflows from "./workflows/Workflows";
import WorkflowView from "./workflows/WorkflowView";
import LoadsAvailableForWorkflow from "./workflows/LoadsAvailableForWorkflow";
import RFP from "./rfp/RFP";
import {getRFPAutomatedLoadsEnabled, getTourAutomatedLoadsEnabled} from "./service/settings";
import Tour from "./tour/Tour";
import Rates from "./rates/Rates";
import TicketAll from './tickets/TicketAll';
import Bids from "./bids/Bids";
import TenderGrid from "./tender/TenderGrid";
import Invoice from "./invoice/Invoice";

export default function App() {

    const [authenticated, setAuthenticated] = useState(!!localStorage.getItem(ACCESS_TOKEN));
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    // when App loads, go and get the current user if authenticated is true
    useEffect(() => {
        // const token = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIyMDY5MjIyIiwiaWF0IjoxNjkyMDAxNTQ1LCJleHAiOjE2OTI4NjU1NDV9.UGu6UbS-FgWhJvl47uzTENfpJEF0W693z-GuuxVFP1PZU2JgjQS5Nkw_WXu63sr5kJMge-8Gj4chdw66d-yBIA'
        // const userData = {"id":2069222,"created":"2023-06-21T08:10:13.090248Z","name":null,"email":"meghkumar@torch3pl.com","imageUrl":"https://lh3.googleusercontent.com/a/default-user=s96-c","shipwellId":null,"password":""}
        // localStorage.setItem(ACCESS_TOKEN, token)
        // localStorage.setItem('userData', JSON.stringify(userData));
        // setCurrentUser(userData);
        // setLoading(false);
        if (localStorage.getItem(ACCESS_TOKEN)) {
            getCurrentUser()
                .then(response => {
                    setCurrentUser(response);
                    setLoading(false);
                }).catch(_ => {
                localStorage.removeItem(ACCESS_TOKEN);
                setAuthenticated(false);
                setLoading(false);
            });
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('userData', JSON.stringify(currentUser));
    }, [currentUser]);

    // const handleLogout = () => {
    //     localStorage.removeItem(ACCESS_TOKEN);
    //     setAuthenticated(false);
    //     setCurrentUser(null);
    // }

    return (
        // overriding state since the constructor isn't called all the time and
        // the existence of a local storage access token is our source of truth of
        // authenticated

        // still not super happy with the current way of state handling this but this
        // can be shored up later - loading is more of a "validating access token" state
        // maybe that can be managed differently?

        <Switch>
            <Route path="/oauth2/redirect" component={OAuth2RedirectHandler}/>
            <Route path="/login"
                   render={(props) => <Login authenticated={authenticated} {...props} />}/>
            <PrivateRoute loading={loading}
                          authenticated={authenticated}
                          currentUser={currentUser}
                          component={AuthenticatedFrame}/>
        </Switch>
    );
}

const TorchMenuIcon = ({name}) => {
    switch (name) {
        case 'Open Loads':
            return <AssignmentTurnedIn/>;
        case 'Loads':
            return <Details />;
        case 'Tracking':
            return <LocationSearchingTwoTone />;
        case 'Rates':
            return <MonetizationOn/>;
        case 'Carriers':
            return <LocalShipping/>;
        case 'Shippers':
            return <AccountBalance/>;
        case 'Leads':
            return <ConnectWithoutContact/>;
        case 'Integrations':
            return <Ballot/>;
        case 'RFP':
            return <RequestQuoteIcon/>;
        case 'Tour':
            return <TourIcon/>;
        case 'Facilities':
            return <Warehouse />;
        case 'Ticket Boards':
            return <AirplaneTicket />;
        case 'Settings':
            return <Settings/>;
        case 'Load Tender':
            return <Queue/>;
        case 'Load TenderGrid':
            return <AllInbox/>;
        case 'Invoice':
            return <Payments/>
        case 'Search':
            return <Search/>
        case 'Bids':
            return <Shop/>
        default:
            return <Settings/>;
    }
}

const AuthenticatedFrame = () => {

    const theme = useTheme();
    const history = useHistory();

    // const [token, setToken] = useState("");
    const [open, setOpen] = useState(false);
    const [needReload, setNeedReload] = useState(false);

    const drawerWidth = 240;

    // useEffect(() => {
    //     getProductFeedbackToken().then(t => setToken(t));
    // }, [])

    useEffect(() => {
        const subscription = socketEvent$.subscribe(value => {
            if (value.type === "REFRESH") {
                setNeedReload(true);
            }
        });
        return function cleanup() {
            console.log("Cleanup socket");
            if (subscription) {
                subscription.unsubscribe();
            }
        }
    }, []);

    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });
    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(7)} + 1px)`,
        },
    });

    const DrawerHeader = styled('div')(({theme}) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({theme, open}) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
        ({theme, open}) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );

    const labels = [
        {key: 'Search', route: '/search'},
        {key: 'Open Loads', route: '/open-loads'},
        {key: 'Bids', route: '/bids'},
        {key: 'Loads', route: '/loads'},
        {key: 'Rates', route: '/rates'},
        {key: 'Facilities', route: '/facilities'},
        {key: 'Carriers', route: '/carriers'},
        {key: 'Load Tender', route: '/tender?days=7'},
        {key: 'Load TenderGrid', route: '/tenderGrid'},
        {key: 'Invoice', route: '/invoice'},
        {key: 'Leads', route: '/leads'},
        {key: 'Integrations', route: '/integrations'},
        {key: 'Ticket Boards', route: '/tickets'}
    ];

    if (getRFPAutomatedLoadsEnabled()) {
        labels.push({key: 'RFP', route: '/rfp'});
    }

    if (getTourAutomatedLoadsEnabled()) {
        labels.push({key: 'Tour', route: '/tour'});
    }


    const secondLabels = [{key: 'Settings', route: '/settings'}];

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const redirect = (item) => {
        history.push(item);
    }

    const SOCKET_URL = API_BASE_URL + '/ws-general/?torch_auth=' + localStorage.getItem(ACCESS_TOKEN);
    const socketHeader = {'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)};

    let onConnected = () => {
        console.log("Connected to socket.")
    }

    let onDisconnect = () => {
        console.log("Disconnected from socket.")
    }

    let onMessageReceived = (msg) => {
        nextMessage(msg);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setNeedReload(false);
    };

    return <>
        <SockJsClient
            url={SOCKET_URL}
            headers={socketHeader}
            topics={['/topic/group', '/topic/load', '/topic/openload']}
            onConnect={onConnected}
            onDisconnect={onDisconnect}
            onMessage={msg => onMessageReceived(msg)}
            debug={true}
        />
        <RecoilRoot>
            <Box sx={{display: "flex"}}>
                <CssBaseline/>
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: '36px',
                                ...(open && {display: 'none'}),
                            }}>
                            <MenuIcon/>
                        </IconButton>
                        {needReload && (
                            <Snackbar open={needReload}
                                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                      autoHideDuration={60000}
                                      onClose={handleClose}>
                                <Alert onClose={handleClose} severity="error">
                                    New data available.
                                    <Button variant="contained" color="secondary"
                                            onClick={() => window.location.reload()}>Reload</Button>
                                </Alert>
                            </Snackbar>
                        )}
                        <Typography sx={{flexGrow: 1}} variant="h6" noWrap>
                            Torch - All Gas, No Brakes
                        </Typography>
                        {/*<SpecialMessage to={"/open-loads"}>*/}
                        {/*    Check out the New Open Loads UI*/}
                        {/*</SpecialMessage>*/}
                        <ReleaseNotesLink />
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                        </IconButton>
                    </DrawerHeader>
                    <Divider/>
                    <List>
                        {labels.map((obj, index) => (
                            <ListItem button key={obj.key} onClick={() => redirect(obj.route)}>
                                <ListItemIcon><TorchMenuIcon name={obj.key}/></ListItemIcon>
                                <ListItemText primary={obj.key}/>
                            </ListItem>
                        ))}
                    </List>
                    <Divider/>
                    <List>
                        {secondLabels.map((obj, index) => (
                            <ListItem button key={obj.key} onClick={() => redirect(obj.route)}>
                                <ListItemIcon><TorchMenuIcon name={obj.key}/></ListItemIcon>
                                <ListItemText primary={obj.key}/>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
                {/* content goes here - the authenticated frame perhaps*/}
                <Box component="main" sx={{flexGrow: 1, p: 0}}>
                    <DrawerHeader/>
                    {/*<Feedback slackToken={token} slackChannel={"#product-feedback"} handleSubmitError={console.log}/>*/}

                    <Switch>
                        <Route path="/rate/:route">
                            <Rate/>
                        </Route>
                        <Route path="/rates">
                            <Rates/>
                        </Route>
                        <Route path="/account">
                            <Account/>
                        </Route>
                        <Route path="/carriers">
                            <Carriers/>
                        </Route>
                        <Route path="/settings">
                            <SettingsMain/>
                        </Route>
                        <Route path="/tender/:loadTenderId">
                            <Tender/>
                        </Route>
                        <Route path="/invoice/">
                            <Invoice/>
                        </Route>
                        <Route path="/tender">
                            <Tender/>
                        </Route>
                        <Route path="/tenderGrid">
                            <TenderGrid/>
                        </Route>
                        <Route path="/leads">
                            <Leads/>
                        </Route>
                        <Route path="/integrations">
                            <Integrations/>
                        </Route>
                        <Route path="/rfp">
                            <RFP/>
                        </Route>
                        <Route path="/tour">
                            <Tour/>
                        </Route>
                        <Route path="/loads">
                            <Loads />
                        </Route>
                        <Route path="/open-loads/:loadId/carrier/:carrierId">
                            <OpenLoadContainer/>
                        </Route>
                        <Route path="/open-loads/:loadId">
                            <OpenLoadContainer/>
                        </Route>
                        <Route path="/open-loads">
                            <OpenLoads/>
                        </Route>
                        <Route path="/search">
                            <StartSearch />
                        </Route>
                        <Route path="/facilities/create">
                            <FacilityCreate />
                        </Route>
                        <Route path="/facilities/:facilityId">
                            <FacilityHome />
                        </Route>
                        <Route path="/facilities">
                            <Facilities />
                        </Route>
                        <Route path={"/workflow/loads-to-track"}>
                            <LoadsAvailableForWorkflow />
                        </Route>
                        <Route path="/workflow/:loadId">
                            <WorkflowView />
                        </Route>
                        <Route path="/workflow">
                            <Workflows />
                        </Route>
                        <Route path="/tickets">
                            <TicketAll />
                        </Route>
                        <Route path="/bids">
                            <Bids />
                        </Route>
                        <Route path="/">
                            <OpenLoads/>
                        </Route>
                        <Route path="*">
                            <NoMatch/>
                        </Route>
                    </Switch>
                </Box>
            </Box>
        </RecoilRoot>
    </>;
}

const NoMatch = () => {
    let location = useLocation();

    return (
        <div>
            <h3>
                No match for <code>{location.pathname}</code>
            </h3>
        </div>
    );
}
