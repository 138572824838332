import {Link, Paper, Snackbar, Typography} from "@mui/material";
import {TenderGridRequest} from "../interfaces/TenderGridRequest";
import React, {useEffect, useState} from "react";
import {Box, styled} from "@mui/system";
import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {acceptRejectTenderedLoad, getAllTenderedLoads} from "../service/tendered";
import {Handshake, ThumbDown} from "@mui/icons-material";


const TenderGridBox = styled(Box)`
  border: 1px grey;
  margin: 20px;
  padding: 10px;
`

const Instruction = styled(Typography)`
   font-weight: 500;
`

type TableProps = {
    rows: TenderGridRequest[];
    onChange: () => void;
}

const StopLocation = styled('div')`
  overflow: clip;
  text-transform: capitalize;
`

const TenderGridTable = ({rows, onChange}: TableProps) => {

    const [tenderSnack, setTenderSnack] = useState(false);
    const [tenderStatus, setBidStatue] = useState("");

    const [tenderLoadingSnack, setTenderLoadingSnack] = useState(false);

    function acceptTender(tenderId:number) {
        setTenderLoadingSnack(true);
        acceptRejectTenderedLoad(tenderId, true, [])
            .then(() => {
                setTenderLoadingSnack(false);
                setTenderSnack(true);
                setBidStatue("Submitted accept for tender" +tenderId);
            });
    }

    function confirmReject(tenderId:number) {
        setTenderLoadingSnack(true);
        acceptRejectTenderedLoad(tenderId, false, [])
            .then(() => {
                setTenderLoadingSnack(false);
                setTenderSnack(true);
                setBidStatue("Submitted reject for tender" + tenderId);
            });
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        setTenderSnack(false);
    };


    function formatDateFromString(date:string | undefined) {
        if (date) {
            return new Date(date).toLocaleDateString('en-us', {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "numeric"
            });
        }
        return "";
    }

    const columns: GridColDef[] = [
        { field: 'shipmentId', headerName: 'Shipment Id', width: 100 ,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Link target={"_blank"} href={`/tender/${params?.row.loadTenderId}`}><Typography>{params?.row.shipmentNumber}</Typography></Link>
            )

        },
        { field: 'loadTenderId', headerName: 'Tender Id', width: 100 ,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Link target={"_blank"} href={`/tender/${params?.row.loadTenderId}`}><Typography>{params?.row.loadTenderId}</Typography></Link>
            )

        },
        { field: 'transactionTime', headerName: 'Transaction Time', width: 150,
            renderCell : (params: GridRenderCellParams<string>) => (
                <Typography>{formatDateFromString(params?.row?.transactionTime)}</Typography>
            )
        },
        { field: 'shipperKey', headerName: 'Shipper', width: 75 },
        { field: 'tenderStatus', headerName: 'Status', width: 150 },
/*        { field: 'tenderState', headerName: 'State', width: 100 },*/
        { field: 'purpose', headerName: 'Purpose', width: 125 },
        { field: 'pickup', headerName: 'Route', width: 175,
            renderCell: (params: GridRenderCellParams<string>) => (
                ( params?.row?.stopInfo.length > 0 ?
                        <StopLocation>
                            <Typography>{params?.row?.stopInfo[0].city + ', ' +  params?.row?.stopInfo[0].state}</Typography>
                            <Typography>&#x2192;</Typography>
                            <Typography>{params?.row?.stopInfo[1].city  + ', ' + params?.row?.stopInfo[1].state}</Typography>
                        </StopLocation>
                        :
                        <Typography>Unknown</Typography>
                )
            )},
        /*{ field: 'dropoff', headerName: 'Dropoff', width: 125,
            renderCell: (params: GridRenderCellParams<string>) => (
                ( params?.row?.stopInfo.length > 0 ?
                        <Typography>{params?.row?.stopInfo[1].city + ',' +  params?.row?.stopInfo[1].state}</Typography>
                        :
                        <Typography>Unknown</Typography>
                )
            )},*/
        { field: 'pickupDate', headerName: 'Pickup Date', width: 150,
            renderCell: (params: GridRenderCellParams<string>) => (
                ( params?.row?.stopInfo.length > 0 ?
                        <Typography>{formatDateFromString(params?.row?.stopInfo[0].stopTime)}</Typography>
                        :
                        <Typography>Unknown</Typography>
                )
            )},
        { field: 'dropoffDate', headerName: 'Dropoff  Date', width: 150,
            renderCell: (params: GridRenderCellParams<string>) => (
                ( params?.row?.stopInfo.length > 0 ?
                        <Typography>{formatDateFromString(params?.row?.stopInfo[1].stopTime)}</Typography>
                        :
                        <Typography>Unknown</Typography>
                )
            )},
        { field: 'run', headerName: 'Accept', width: 70,
            renderCell: (params: GridRenderCellParams<number>) => (
                <Handshake onClick={() => acceptTender(params?.row.loadTenderId)}/>
            ),
        },
        { field: 'remove', headerName: 'Reject', width: 70,
            renderCell: (params: GridRenderCellParams<number>) => (
                <ThumbDown onClick={() => confirmReject(params?.row.loadTenderId)}/>
            ),
        }
    ];

    return (
        <div>
            <Snackbar
                open={tenderSnack}
                autoHideDuration={5000}
                message={tenderStatus}
                onClose={handleClose}
            />
            <Snackbar
                open={tenderLoadingSnack}
                autoHideDuration={5000}
                message={"Saving..."}
            />
    <Paper sx={{ height: 1000, width: '100%' }}>
        <DataGrid
            rows={rows}
            columns={columns}
            sx={{
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.light',
                '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                }}}
            getRowId={(row: any) => row.loadTenderId}
            autoHeight
            getRowHeight={() => 'auto'}
            disableSelectionOnClick
        />
    </Paper>
        </div>
    );
}

const TenderGrid = () => {

    const [rows, setRows] = useState<TenderGridRequest[]>();
    function getTenderGridList() {
        getAllTenderedLoads().then(data => {
            setRows(data);
        })
    }

    useEffect(() => {
        getTenderGridList();
    }, []);


    return (
        <div className={"TenderGrid-tools"}>

            <TenderGridBox>
                <Instruction className={"integrations-title"}>
                </Instruction>
                {rows ?
                    <TenderGridTable onChange={getTenderGridList} rows={rows}></TenderGridTable>
                    :
                    <div>Loading Tenders...</div>
                }
            </TenderGridBox>
        </div>
    );
}
export default TenderGrid;
