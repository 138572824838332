import React, {useEffect, useState} from "react";
import FacilityDetails from "./FacilityDetails";
import {Load, SkinnyLoad, Stop} from "../interfaces/Load";
import SectionContainer from "../common/SectionContainer";
import {Button, DialogContentText, Typography, useMediaQuery} from "@mui/material";
import Moment from "react-moment";
import 'moment-timezone';
import "./LoadStops.scss";
import {useHistory, useParams} from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {Torchers} from "../common/Torchers";
import {TorchShipmentExternalLink} from "../common/Links";
import {getLabelForPayloadType} from "../util/load-utils";
import {useInterval} from "../util/hooks";
import {sendLoadUsageEvent} from "../service/events";
import {getTimeFormat} from "../service/time";
import {Facility} from "../interfaces/Facility";
import {Weather} from "../interfaces/Weather";
import {useTheme} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Box, styled} from "@mui/system";
import DialogActions from "@mui/material/DialogActions";
import {fetchWeatherForLoad} from "../service/loads";
import SevereColdIcon from "@mui/icons-material/SevereCold";

const delay = 20000;

type PropTypes = {
    load: Load | null;
    loadsInLane: SkinnyLoad[] | null;
}

type WeatherProps = {
    cityState?: string;
    stop:string;
}



export const WeatherContainer = ({cityState, stop}: WeatherProps) => {

    const WeatherComponent = styled('div')`
        grid: 'weather';
    `

    const [weatherValue, setWeatherValue] = useState<Weather>();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (cityState) {
            fetchWeatherForLoad(cityState).then((weather) => {
                setWeatherValue(weather);
            });
        }
    }, [cityState]);

    const handleClose = () => {
        setOpen(false);
    };

    return <div>
        { weatherValue ?
            <div>
                { stop === 'pickup' ?
                    weatherValue && weatherValue?.features?.length > 0 ?
                        <WeatherComponent><SevereColdIcon sx={{paddingTop:1, marginTop:0}} fontSize="small" ></SevereColdIcon><Button sx={{fontSize:9}} onClick={() => {
                            setOpen(true);
                            setWeatherValue(weatherValue);
                        }}>Weather Alert</Button></WeatherComponent>
                        :
                        <></>

                    :
                    weatherValue && weatherValue?.features?.length > 0 ?
                        <WeatherComponent><SevereColdIcon sx={{paddingTop:1, marginTop:0}} fontSize="small"></SevereColdIcon><Button sx={{fontSize:9}} onClick={() => {
                            setOpen(true);
                            setWeatherValue(weatherValue);
                        }}>Weather Alert</Button></WeatherComponent>
                        :
                        <></>
                }
            </div>
            :
            <></>
        }
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {weatherValue?.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {weatherValue && weatherValue.features.length === 0 ?
                        <Typography>No Alerts!</Typography>
                        :
                        <div>
                            {
                                weatherValue?.features?.map(feat =>
                                    <Box sx={{marginBottom: 5}}>
                                        <Typography>{feat.properties.description}</Typography>
                                    </Box>
                                )
                            }
                        </div>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    </div>

}

const LoadStops = ({load = null, loadsInLane = null}: PropTypes) => {
    const history = useHistory();
    const params = useParams();
    const index = loadsInLane?.findIndex(t => load && t.id === load.id) || 0;

    const [selectedFacility, setSelectedFacility] = useState<Facility | null>(null);
    const [selectedStop, setSelectedStop] = useState<Stop | null>(null);

    const [taskIndex, setTaskIndex] = useState(index);

    if (load === null || loadsInLane === null) {
        return <SectionContainer title={"Stops"}
                                 noContent={true}
                                 noContentMessage={"No Stop Information"}/>
    }

    const pickup = load.stops[0];
    const dropoff = load.stops[load.stops.length - 1];

    function previousLoad(load: Load, loadsInLane: SkinnyLoad[]) {
        const newIndex = -1 + loadsInLane.findIndex(t => load && t.id === load.id);
        if (newIndex > -1) {
            setTaskIndex(newIndex);
            if (params['carrierId']) {
                history.push(`/open-loads/${loadsInLane[newIndex].id}/carrier/${params['carrierId']}`);
            } else {
                history.push(`/open-loads/${loadsInLane[newIndex].id}`)
            }
        }
    }

    function nextLoad(load: Load, loadsInLane: SkinnyLoad[]) {
        const newIndex = 1 + loadsInLane.findIndex(t => load && t.id === load.id);
        if (newIndex < loadsInLane.length) {
            setTaskIndex(newIndex);
            if (params['carrierId']) {
                history.push(`/open-loads/${loadsInLane[newIndex].id}/carrier/${params['carrierId']}`);
            } else {
                history.push(`/open-loads/${loadsInLane[newIndex].id}`)
            }
        }
    }

    const Title = () => {

        useInterval(async () => {
            sendLoadUsageEvent(load.id);
        }, delay);

        const activityFilter = (message, load) => {
            return message.type === "LOAD" && parseInt(message.content) === load.id;
        };

        return <div className={"stops-title"}>
            <div className={"torchers"}><Torchers type={"mini"} task={load} applyFilter={activityFilter}/></div>
            <div className={"title"}>
                <TorchShipmentExternalLink shipwellId={load.shipwellId}>
                    {load.shipwellRefId}
                </TorchShipmentExternalLink>
                &nbsp;Stops
            </div>
            <Button aria-label="previous load" size="small" sx={{padding: 0}}
                    disabled={!loadsInLane || taskIndex <= 0}
                    startIcon={<ChevronLeftIcon/>}
                    variant="text" onClick={() => previousLoad(load, loadsInLane)}>
                Previous
            </Button>
            <Button aria-label="next load" size="small" sx={{padding: 0}}
                    disabled={!loadsInLane || taskIndex >= loadsInLane.length - 1}
                    endIcon={<ChevronRightIcon/>}
                    variant="text" onClick={() => nextLoad(load, loadsInLane)}>
                Next
            </Button>
        </div>
    }

    type FacilityTagProps = {
        stop: Stop;
    };

    const FacilityTag = ({stop}: FacilityTagProps) => {

        function toggleFacility(facility: Facility | undefined) {
            if (selectedFacility === facility) {
                setSelectedFacility(null);
                setSelectedStop(null);
                return;
            }

            if (facility) {
                setSelectedFacility(facility);
                setSelectedStop(stop);
            }
        }

        if (stop.facility) {
            return <Typography className={"facility-link"}
                        onClick={() => toggleFacility(stop.facility)}>{stop.facility.name}</Typography>
        }

        return <Typography>No Facility Assigned</Typography>
    }

    return <SectionContainer title={<Title/>} className={"lane-panel"}>
        <div className={"stop-container"}>
            <div className={"pickup"}>
                <Typography className={"header"}>Pickup</Typography>
                <Typography>{pickup.address.streetOne}</Typography>
                <Typography>{pickup.address.city}, {pickup.address.state} {pickup.address.zipCode}</Typography>
                {/* simplify this to a single call */}
                <Typography><Moment format={getTimeFormat()}
                                    tz={pickup.timeZoneId}>{pickup.arrival}</Moment></Typography>
                <FacilityTag stop={pickup}/>
                <WeatherContainer stop={"pickup"} cityState={load.stops[0].address.state}></WeatherContainer>
            </div>
            <div className={"dropoff"}>
                <Typography className={"header"}>Dropoff</Typography>
                <Typography>{dropoff.address.streetOne}</Typography>
                <Typography>{dropoff.address.city}, {dropoff.address.state} {dropoff.address.zipCode}</Typography>
                <Typography><Moment format={getTimeFormat()}
                                    tz={dropoff.timeZoneId}>{dropoff.arrival}</Moment></Typography>
                <FacilityTag stop={dropoff}/>
                <WeatherContainer stop={"dropoff"} cityState={load.stops[1].address.state}></WeatherContainer>
            </div>
            <div className={"stuff"}>
                <Typography className={"header"}>Load</Typography>
                <Typography className={"short-description"}>{load.shortDescription} / {load.weight} Pounds</Typography>
                <Typography>{getLabelForPayloadType(load.payloadType)} / {load.stops.length} Stops</Typography>
                <Typography>{load.shipper ? load.shipper.name: "Unknown"}</Typography>
            </div>
        </div>
        {
            selectedFacility && selectedStop ?
                <FacilityDetails facility={selectedFacility} stop={selectedStop}/>
                : null
        }
    </SectionContainer>
}

export default LoadStops;
