import {
    Alert,
    Card,
    CardActions,
    CardContent,
    Collapse,
    LinearProgress,
    Link,
    Paper,
    Snackbar,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Box, styled} from "@mui/system";
import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {LoadLane, SkinnyLoad} from "../interfaces/Load";
import {getDeliveryStop, getPickupStop, getShortDescription} from "../util/load-utils";
import {differenceInCalendarDays, startOfDay} from "date-fns";
import {useHistory} from "react-router-dom";
import {Weather} from "../interfaces/Weather";
import {fetchWeatherForLoad, getLoadCapacity} from "../service/loads";
import Button from "@mui/material/Button";
import {Capacity} from "../interfaces/Capacity";
import {Tags} from "../common/Tags";
import {TruckType} from "../common/TruckType";


const OpenLoadGridBox = styled(Box)`
  border: 1px grey;
  margin: 0px;
  padding: 0px;
`

const Instruction = styled(Typography)`
   font-weight: 500;
`

type TableProps = {
    rows: LoadLane[];
    onChange: () => void;
}

const OpenLoadGridTable = ({rows, onChange}: TableProps) => {

    const [openLoadSnack, setOpenLoadSnack] = useState(false);

    const [openLoadLoadingSnack] = useState(false);

    const history = useHistory();

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        setOpenLoadSnack(false);
    };

    type RowProps = {
        lane: LoadLane;
        stopIndex?:number
    }

    const LoadLabel = ({lane}: RowProps) => {
        const pickup = getPickupStop(lane.loads[0]).address;
        const delivery = getDeliveryStop(lane.loads[0]).address;
        return <>
            <span className={"city"}>{pickup.city.toLowerCase()}</span>
            &nbsp;
            <span className={"state"}>{pickup.state}</span>
            &nbsp;&#8594;&nbsp;
            <span  className={"city"}>{delivery.city.toLowerCase()}</span>
            &nbsp;
            <span className={"state"}>{delivery.state}</span>
        </>
    }

    // this component is generating warnings around missing key properties even though they
    // are set
    const LoadsByDay = ({lane}: RowProps) => {
        const dayStart = startOfDay(new Date());
        const labels = ["SU","M","T","W","TH","F","SA"];
        const todayDow = dayStart.getDay();
        let buckets: number[] = [0,0,0,0,0,0,0];
        let past = 0;

        lane.loads.forEach(load => {
            let between = differenceInCalendarDays(new Date(load.stops[0].arrival), new Date());

            if (between < 0) {
                past++;
            } else {
                buckets[between] += 1;
            }
        });

        let result: any[] = [<span className={"day-container"} key={"od"}><span className={"day overdue"}>OD </span><span className={past > 0 ? "number high" : "number "}>{past}</span></span>];
        for (let i = 0; i < 7; i++) {
            const priority = i < 2 ? "high" : i < 3 ? "med" : "low";
            result.push(<span className={"day-container"} key={i}><span className={"day"}>{labels[(todayDow + i) % 7] + " "}</span>
                <span className={buckets[i] > 0 ? "number " + priority : "number"}>{buckets[i]}</span></span>);
        }

        return <div className={"loads-by-day"}>{result.reduce((prev, curr) => [prev, ' ', curr])}</div>;
    }

    const LoadCapacity = ({lane}: RowProps) => {
        const history = useHistory();
        const[capacities, setCapacities] = useState<Capacity[]>();
        useEffect( () => {
            getLoadCapacity(lane.loads[0].id).then((capacities) => {
                setCapacities(capacities);
            });
        }, [lane.loads]);

        const goToLane  = (loadId:number, carrierId) => {
            history.push(`/open-loads/${loadId}/carrier/${carrierId}`);
        }

        return <>
            {
                capacities && capacities.length > 0 ?
                    <Typography className={'capacity'}>Recent Capacity</Typography>
                    :
                    <><LinearProgress/></>
            }
            {capacities?.slice(0, 5).map(capacity =>
                <div  className={'capacity'}>
                    <Link onClick={ () => goToLane(lane.loads[0].id, capacity.carrier.id)}>{capacity.carrier.name}</Link>
                </div>
            )}
        </>
    }

    const selectLoad = (load: SkinnyLoad) => {

        history.push(`/open-loads/${load.id}`)

    }

    const LoadWeather = ({lane, stopIndex}: RowProps) => {
        const[weather, setWeather] = useState<Weather>();
        const [expanded, setExpanded] = React.useState(false);
        const handleExpandClick = () => {
            setExpanded(!expanded);
        };

        useEffect( () => {
            if (stopIndex) {
            fetchWeatherForLoad(lane.loads[0].stops[stopIndex].address.state).then((weather) => {
                setWeather(weather);
            });
        }

        }, [stopIndex, lane.loads]);

        return <>
            {
                weather && weather.features && weather.features.length > 0 ?
                    <div>
                        <Card>
                            <Typography><Alert severity="error">{stopIndex === 0 ? "Pickup Alert:" : "Dropoff Alert:"} {weather.title}</Alert></Typography>
                            <CardActions>
                                <Button
                                    onClick={handleExpandClick}
                                >
                                    { expanded ?  "Hide Alerts" : "Show " + weather.features.length + " Weather Alerts"}
                                </Button>
                            </CardActions>
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <Typography paragraph>
                                        {
                                            weather.features.map(feat =>
                                                <Box sx={{marginBottom:5}}>
                                                    <Typography>{feat.properties.description}</Typography>
                                                </Box>
                                            )
                                        }
                                    </Typography>
                                </CardContent>
                            </Collapse>
                        </Card>
                    </div>
                    :
                    <></>
            }
        </>
    }


    const columns: GridColDef[] = [
/*        { field: 'loadId', headerName: 'Load Id', width: 100 ,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Link target={"_blank"} href={`/open-loads/${params?.row.loadId}`}><Typography>{params?.row.loadId}</Typography></Link>
            )

        },*/
        { field: 'lane', headerName: 'Lane', width: 375,
            renderCell : (params: GridRenderCellParams<string>) => (
                <div className={'grid-card-content'} onClick={() => selectLoad(params.row.loads[0])}>
                <div className={"title"}>
                    <span className={"count"}>{params.row.loadCount}</span>
                    <span className={"lane-name"}><LoadLabel lane={params.row}/></span>
                </div>
                </div>
            )
        },
        { field: 'laneInfo', headerName: 'Details', width: 200,
            renderCell : (params: GridRenderCellParams<string>) => (
                <div className={'grid-card-content'}>
                <div className={"lane-breakdown"}>
                    <Tags task={params.row.loads[0]}/>
                    <span className={"miles"}>{params.row.loads[0].miles} Miles</span>
                    {
                        params.row.loads[0].miles < 650 ?
                            <span className={"short-haul-label"}>Short Haul</span>
                            : null
                    }
                    <span className={"load-description"}>{getShortDescription(params.row.loads[0])}</span>
                    <span className={"truck-type"}><TruckType task={params.row.loads[0]}/></span>
                </div>
                </div>
            )
        },
        { field: 'loads', headerName: 'Loads by Day', width: 230,
            renderCell : (params: GridRenderCellParams<string>) => (
                <div className={'grid-card-content'}>
                    <div className={"daily-breakdown"}>
                        <LoadsByDay lane={params.row}></LoadsByDay>
                    </div>
                </div>
            )
        },
        { field: 'capacity', headerName: 'Capacity', width: 200,
            renderCell : (params: GridRenderCellParams<string>) => (
                <div className={'grid-card-content'}>
                    <LoadCapacity lane={params.row}></LoadCapacity>
                </div>
            )
        },
        { field: 'weather', headerName: 'Weather', width: 350,
            renderCell : (params: GridRenderCellParams<string>) => (
                <div className={'grid-card-content'}>
                    <LoadWeather lane={params.row} stopIndex={0}></LoadWeather>
                    <LoadWeather lane={params.row} stopIndex={1}></LoadWeather>
                </div>
            )
        }
    ];

    return (
        <div>
            <Snackbar
                open={openLoadSnack}
                autoHideDuration={5000}
                onClose={handleClose}
            />
            <Snackbar
                open={openLoadLoadingSnack}
                autoHideDuration={5000}
                message={"Saving..."}
            />
            <Paper sx={{ height: 1000, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    sx={{
                        boxShadow: 2,
                        border: 2,
                        borderColor: 'primary.light',
                        '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main',
                        }}}
                    getRowId={(row: any) => row.laneId}
                    autoHeight
                    getRowHeight={() => 'auto'}
                    disableSelectionOnClick
                    loading={true}
                />
            </Paper>
        </div>
    );
}

const OpenLoadGrid = ({rows, onChange}: TableProps) => {

    //const [setRows] = useState<LoadLane[]>(rows);
    function getOpenLoadGridList() {
        //TODO:
        /*        getAllOpenLoadedLoads().then(data => {
            setRows(data);
        })*/
    }

    useEffect(() => {
        getOpenLoadGridList();
    }, []);


    return (
        <div className={"OpenLoadGrid-tools"}>

            <OpenLoadGridBox>
                <Instruction className={"integrations-title"}>
                </Instruction>
                {rows ?
                    <OpenLoadGridTable onChange={getOpenLoadGridList} rows={rows}></OpenLoadGridTable>
                    :
                    <div>Loading Open Loads... .</div>
                }
            </OpenLoadGridBox>
        </div>
    );
}
export default OpenLoadGrid;
