import {
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Typography
} from "@mui/material";
import {InvoiceRequest} from "../interfaces/InvoiceRequest";
import React, {useEffect, useState} from "react";
import {Box, styled} from "@mui/system";
import {DataGrid, GridColDef, GridRenderCellParams, GridRowModel} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import {buildSelectedCSV, getSkinnyDeliveredLoadsDaysBack} from "../service/loads";
import {ACCESS_TOKEN, API_BASE_URL} from "../constants";
import DoneIcon from '@mui/icons-material/Done';
import AlarmIcon from '@mui/icons-material/Alarm';

const InvoiceBox = styled(Box)`
    border: 1px grey;
    margin: 20px;
    padding: 10px;
`

const Instruction = styled(Typography)`
    font-weight: 500;
`

type TableProps = {
    rows: InvoiceRequest[];
    onChange: (days: string) => void;
}

const StopLocation = styled('div')`
    overflow: clip;
    text-transform: capitalize;
`

const InvoiceTable = ({rows, onChange}: TableProps) => {

    const [selected, setSelected] = useState<string[]>([]);
    const [daysBack, setDaysBack] = useState("14");

    function formatDateFromString(date: string | undefined) {
        if (date) {
            return new Date(date).toLocaleDateString('en-us', {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "numeric"
            });
        }
        return "";
    }

    const selectLoad = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("*** 0 size: " + selected.length + " " + JSON.stringify(selected) + " : " + event.target.name);
        if (event.target.checked) {
            setSelected(prevState => [...prevState, event.target.name]);
        } else {
            setSelected(selected.filter(i => i !== event.target.name));
        }
        console.log("*** 1 size: " + selected.length + " " + JSON.stringify(selected) + " : " + event.target.name);
    };

    function buildSelected() {
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = `csv_combined.csv`;
        buildSelectedCSV(selected)
            .then((res) => {
                link.href = URL.createObjectURL(
                    new Blob([res], {type: "application/csv"})
                );
                link.click();
            });
    }

    function hasInvoiceTag(row: GridRowModel<any> | undefined) {
        return row.tags.find(tag => tag.name === 'CUSTOMER INVOICE SENT');
    }

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'Id', width: 100},
        {
            field: 'shipwellRefId', headerName: 'Load Id', width: 100,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Link target={"_blank"}
                      href={`/loads/${params?.row.id}`}><Typography>{params?.row.shipwellRefId}</Typography></Link>
            )

        },
        {
            field: 'shipwellId', headerName: 'Shipwell', width: 100,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Link target={"_blank"}
                      href={`https://torch.shipwell.com/shipments/${params?.row.shipwellId}`}><Typography>OPEN</Typography></Link>
            )
        },
        {
            field: 'shipperKey', headerName: 'Shipper', width: 150,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Typography>{params?.row?.shipperName}</Typography>
            )
        },
        {
            field: 'carrierKey', headerName: 'Carrier', width: 150,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Typography>{params?.row?.carrierName}</Typography>
            )
        },
        {
            field: 'pickup', headerName: 'Route', width: 150,
            renderCell: (params: GridRenderCellParams<string>) => (
                (params?.row?.stops.length > 0 ?
                        <StopLocation>
                            <Typography>{params?.row?.stops[0].address.city + ', ' + params?.row?.stops[0].address.state}</Typography>
                            <Typography>&#x2192;</Typography>
                            <Typography>{params?.row?.stops[1].address.city + ', ' + params?.row?.stops[1].address.state}</Typography>
                        </StopLocation>
                        :
                        <Typography>Unknown</Typography>
                )
            )
        },
        {
            field: 'dropoffDate', headerName: 'Dropoff  Date', width: 150,
            renderCell: (params: GridRenderCellParams<string>) => (
                (params?.row?.stops.length > 0 ?
                        <Typography>{formatDateFromString(params?.row?.stops[1].arrival)}</Typography>
                        :
                        <Typography>Unknown</Typography>
                )
            )
        },
        {field: 'documentsCount', headerName: '# Documents', width: 100},
        {field: 'invoiceStatus', headerName: 'Invoice Sent', width:110,
            renderCell: (params: GridRenderCellParams<string>) => (
                (hasInvoiceTag(params?.row) ?
                    <DoneIcon></DoneIcon>
                    :
                    <AlarmIcon></AlarmIcon>
                )
            )
        },
        {
            field: 'downloadInvoice', headerName: 'Invoice', width: 120,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Button variant="outlined"
                        href={API_BASE_URL + `/invoice/pdf/${params?.row.id}?torch_auth=` + localStorage.getItem(ACCESS_TOKEN)}>Download</Button>
            )

        },
        {
            field: 'choose', headerName: 'Select', width: 70,
            renderCell: (params: GridRenderCellParams<number>) => (
                <Checkbox onChange={selectLoad} value={params?.row.id} name={params?.row.id}></Checkbox>
            ),
        }
    ];

    const handleChange = (event: SelectChangeEvent) => {
        setDaysBack(event.target.value);
        onChange(event.target.value);
        setSelected([]);
    };

    return (
        <div>
            <Paper sx={{height: 1000, width: '100%'}}>
                <Grid container spacing={4} sx={{marginBottom: 1}}>
                    <Grid item xs={8}>
                        <FormControl variant="standard" sx={{m: 1, minWidth: 300}}>
                            <InputLabel id="demo-simple-select-standard-label">Delivered loads looking
                                back...</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={daysBack}
                                onChange={handleChange}
                                label="Days Back"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={1}>1 day</MenuItem>
                                <MenuItem value={2}>2 days</MenuItem>
                                <MenuItem value={7}>1 week</MenuItem>
                                <MenuItem value={14}>2 weeks</MenuItem>
                                <MenuItem value={30}>1 month</MenuItem>
                                <MenuItem value={60}>2 months</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <Button sx={{marginTop: 2}} variant="outlined" onClick={buildSelected}>Download Invoice for
                            Loads({selected.length})</Button>
                    </Grid>
                </Grid>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    sx={{
                        boxShadow: 2,
                        border: 2,
                        borderColor: 'primary.light',
                        '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main',
                        }
                    }}
                    getRowId={(row: any) => row.id}
                    autoHeight
                    getRowHeight={() => 'auto'}
                    disableSelectionOnClick
                />
            </Paper>
        </div>
    );
}

const Invoice = () => {

    const [rows, setRows] = useState<InvoiceRequest[]>();

    function getInvoiceList() {
        getSkinnyDeliveredLoadsDaysBack("14").then(data => {
            setRows(data);
        })
    }

    function getInvoiceListDaysBack(daysBack: string) {
        getSkinnyDeliveredLoadsDaysBack(daysBack).then(data => {
            setRows(data);
        })
    }

    useEffect(() => {
        getInvoiceList();
    }, []);


    return (
        <div className={"Invoice-tools"}>

            <InvoiceBox>
                <Instruction className={"integrations-title"}>
                </Instruction>
                {rows ?
                    <InvoiceTable onChange={getInvoiceListDaysBack} rows={rows}></InvoiceTable>
                    :
                    <div>Loading Invoices...</div>
                }
            </InvoiceBox>
        </div>
    );
}
export default Invoice;
